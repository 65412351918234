import React from 'react'

import "./Footer.css";
import stringsEle from '../../../assets/stringElements.json'


export const Footer = () => {
    return (
        <div className='footer-wrapper'>{stringsEle.footerText}</div>
    )
}

export default Footer;