import React from 'react'
import { signOut } from '../../../utils/AuthenticationLibrary';
import "./UserSection.css";

export const UserSection = ({ loggedIn = false }) => {
    return (
        <div className='userSection-wrapper'>
            {loggedIn ?
                <>
                    <div className='userName'>
                        Admin
                        <span><i className='fa fa-user'></i></span>
                    </div>
                    <div className='logoutButton' onClick={() => signOut()}>
                        <span><i className='fa fa-sign-out'></i></span>
                    </div>
                </>
                :
                <>
                    <div>Login</div>
                    <div>Signup</div>
                </>

            }
        </div>
    )
}

export default UserSection;