import React from 'react'
import MultiSelectDropdown from '../dropdown/MultiSelectDropdown'
import ModalBasic from '../Model/FormModel'
import CustomButton from '../ui/customButton/CustomButton'
import CancelButton from '../ui/CancelButton'
import PageLoader from '../common/pageLoader/PageLoader'



export default function RecordDetailModel(props) {


    return (
        <ModalBasic
            id="add-edit-strategic-member-modal"
            modalOpen={props.setAddEditBatchUpload}
            setModalOpen={props.setOpenModal}
            title="Record Details"
            noCloseIcon={false}
        >
            {props.isLoadingForCampaign === true ? <PageLoader /> : null}

            <div className="main-body-section">


                <div className="grid grid-cols-12 gap-4 mb-2">
                    <div className="col-span-9 lg:col-span-12 md:col-span-12 w-full">
                        <div className="flex items-center">
                            <h3 class="font-bold text-[#757575] text-xl w-[250px]">
                                Date of Upload:
                            </h3>
                            <div className="col-span-12 text-xl">
                                <h1>{props.batchRecordDetails.dateOfUpload}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-9 lg:col-span-12 md:col-span-12 w-full">
                        <div className="flex items-center">
                            <h3 class="font-bold text-[#757575] text-xl w-[250px]">
                                Source of Upload:
                            </h3>
                            <div className="col-span-12 text-xl">
                                <h1>{props.batchRecordDetails.sourceName}</h1>
                            </div>

                        </div>
                    </div>
                    <div className="col-span-9 lg:col-span-12 md:col-span-12 w-full">
                        <div className="flex items-center">
                            <h3 class="font-bold text-[#757575] text-xl w-[250px]">
                                Number of Records:
                            </h3>
                            <div className="col-span-12 text-xl">
                                <h1>{props.batchRecordDetails.numberOfRecords}</h1>
                            </div>

                        </div>
                    </div>
                    <div className="col-span-9 lg:col-span-12 md:col-span-12 w-full">
                        <div className="flex items-center">
                            <h3 class="font-bold text-[#757575] text-xl w-[250px]">
                                Total Amount:
                            </h3>
                            <div className="col-span-12 text-xl">
                                <h1> {props.batchRecordDetails.totalAmount}</h1>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto mt-9 table-main-over-flow-sec">
                    <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200 ">
                        <thead className="table-bg text-xl bg-white font-semibold capitalize border-t text-[#333333] border-b border-slate-200 w-full">
                            <tr>
                                <th className="px-2 text-left text-[20px] border-r border-slate-200 first:pl-5 last:pr-5 tbl-th whitespace-nowrap w-[60%]">
                                    Source Campaign
                                </th>
                                <th className="px-2 text-center text-[20px] first:pl-5 border-r border-slate-200 last:pr-5 tbl-th whitespace-nowrap w-[15%]">
                                    No.of Records
                                </th>
                                <th className="px-2 text-center text-[20px] first:pl-5 last:pr-5 tbl-th whitespace-nowrap w-[25%]">
                                    Target Campaign
                                </th>
                            </tr>
                        </thead>
                        <tbody className="  custom-tbody  text-[#757575] text-xl">

                            {props.batchRecordDetails.campaignList.map((campaign, index) => {
                                return (
                                    props.donationCampaign.find((item) => item.label === campaign.recipientCampaign) ? null :
                                        <tr className="" key={`Campaign/B${campaign.batchRecordBasicDetailsId}/I${index}`}>

                                            <td className="p-4 text-left text-[20px] border-slate-200 border-r w-[60%]">{campaign.recipientCampaign}
                                            </td>

                                            <td className="p-4 text-center text-[20px] border-slate-200 border-r w-[15%]">{campaign.numberOfRecords}
                                            </td>

                                            <td className="p-4 text-center text-[20px] w-[25%]">
                                                <MultiSelectDropdown
                                                    options={props.donationCampaign}
                                                    //isMulti
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    onChange={(selected, drpIdentity) => props.handleChangeInDropdown(selected, drpIdentity, index)}
                                                    allowSelectAll={false}
                                                    value={props.donationCampaign.find((item) => item.label === campaign.targetCampaignName && campaign.targetCampaignName != null)}
                                                    drpIdentity="TargetCampaign"
                                                />



                                            </td>
                                        </tr>

                                )

                            })}





                        </tbody>
                    </table>

                </div>

                <div class="bg-[#e3f4cf] text-[#77c91e] px-4 py-3 mt-7 mb-7  relative" role="alert">
                    <strong class="font-semibold text-2xl">All records uploaded succesfully.</strong>

                </div>
                <div className="flex flex-wrap justify-between space-x-10 mt-12">


                    <CancelButton
                        text="Cancel"
                        width="100px"
                        onClick={props.setOpenModal}
                    // onClick={(e) => { onClose(e) }}
                    />
                    <CustomButton
                        text="Submit"
                        width="100px"
                        onClick={props.updateTargetCampaign}
                    />
                </div>
            </div>
            <div>
                {/* <DuplicateRecordDetailModel
                    duplicateRecordDetails={props.duplicateRecordDetails}
                    setOpenModal={setDuplicateRecordDetails(true)}
                    onClose={(e) => {
                        e.stopPropagation();
                        setDuplicateRecordDetails(false);
                    }}
                //  optionForDropdowns={this.state.optionForDropdowns}
                //   handleChangeInDropdown={this.handleChangeInDropdown.bind(this)} 


                /> */}
            </div>
        </ModalBasic >

    )
}
