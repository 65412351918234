import { ActiveInActiveAccountById, AddEditAdminAccount, DeleteAdminUser, GetAdminAccountList, GetAdminDetailsById, GetAllRoles, StoreBase64Image} from "../ApiEndPoints";
import { axiosGet, axiosPost, axiosPostWithoutEncryption } from "../AxiosRequests";

export default class AccountManagementServices {
    
    async addEditAccountDetails(request) {
        return axiosPost(AddEditAdminAccount, request);
    }
    async getAllroles(){
        return axiosGet(GetAllRoles);
    }
    async storeBase64Image(request) {
        return axiosPostWithoutEncryption(StoreBase64Image, request, false);
    }
    async getAdminAccountList(request){
        return axiosPost(GetAdminAccountList,request);
        
    }
    async getAdminDetailsById(request){
        return axiosGet(GetAdminDetailsById,request);
    }
    async activeInActiveAdmin(request){
        return axiosPost(ActiveInActiveAccountById,request);
    }
    async deleteAdminUser(request){
        return axiosPost(DeleteAdminUser,request);
    }
}