import React, { useState } from "react";
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import Sidebar from "../../components/shared/sidebar/Sidebar";
import { Outlet } from "react-router";
import Container from "../../components/shared/container/Container";

export default function Layout(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navOpen, setnavOpen] = useState(false);

  const toggleNav = () => {
    // this.setState({ navOpen: !this.state.navOpen })
    setnavOpen(!navOpen);
  };

  return (
    <div>
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            componentRoutes={props.componentRoutes}
          />
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}
