import React from 'react'

const PageLoader = () => {
  return (
    <div>
     <div className="loader_container">
        <section>
          <div className="loader loader-1">
            <div className="loader-outter"></div>
            <div className="loader-inner"></div>
          </div>
        </section>
     </div>
    </div>
  )
}

export default PageLoader
