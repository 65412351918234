import React from "react";
import './App.css';
import Login from './pages/authentication/Login';
import './css/common.css'
import { getAuthProps, signOut } from './utils/AuthenticationLibrary';
import ComponentNavigation from './navigation/ComponentNavigation';
import LayoutNavigation from './navigation/LayoutNavigation';
function App() {
  const authDetails = getAuthProps();

  if (window.location.pathname === "/") {
    signOut();
  }
  else if(window.location.pathname !== "/Login"){
    if (authDetails) {
      ComponentNavigation.forEach(element => {
        element.authDetails = authDetails;
      });
    }
    else {
      window.location.href = "/Login";
    }
  }

  return (
    <LayoutNavigation componentRoutes={ComponentNavigation} page={ComponentNavigation.component} authDetails={authDetails}/>
  );
}

export default App;
