import { IsProdMode } from "../../utils/AppSetting";

export const APIURL = (IsProdMode) ? process.env.REACT_APP_PreProdApiURL : process.env.REACT_APP_DevApiURL;

//Authentication services
export const AdminLogin = process.env.REACT_APP_AdminLogin;


// Account Management Services
export const AddEditAdminAccount = "AccountManagement/AddEditAdminAccount";
export const GetAllRoles = "AccountManagement/GetAllRoles";
export const GetImage = "AccountManagement/GetImage";
export const StoreBase64Image = "AccountManagement/StoreBase64Image";
export const GetAdminAccountList = "AccountManagement/GetAdminAccountList";
export const getPartnerLinkList = "patnerlinks/getPartnerLinkList"
export const GetAdminDetailsById = "AccountManagement/GetAdminDetailsById?id={0}";
export const ActiveInActiveAccountById = "AccountManagement/ActiveInActiveAccountById";
export const DeleteAdminUser = "AccountManagement/DeleteAdminUser";



// Partner Link
export const GetAllPartner = "PartnerLinks/GetAllPartner";
export const GetAllDonationPurpose = "PartnerLinks/GetAllDonationPurpose";
export const GetAllDonationPurposeBatchUpload = "PartnerLinks/GetAllDonationPurposeBatchUpload";
export const GeneratePartnerLink = "PartnerLinks/GeneratePartnerLink?partnerName={0}&donationPurpose={1}&isActive={2}";
export const GetPartnerLinkList = "PartnerLinks/GetPartnerLinkList";
export const GetPartnerLinkListById = "PartnerLinks/GetPartnerLinkListById?id={0}";
export const AddEditPartnerLink = "PartnerLinks/AddEditPartnerLink";
export const ActiveInActivePartnerLinkById = "PartnerLinks/ActiveInActivePartnerLinkById";
export const DeletePartnerLink = "PartnerLinks/DeletePartnerLink";

//Batch Upload
export const DownloadGiveAsiaTemplete = "FileImport/DownloadGiveAsiaTemplete";
export const DownloadGivingSGTemplete = "FileImport/DownloadGivingSGTemplete";
export const DownloadMasterTemplete = "FileImport/DownloadMasterTemplete";
export const AddFile = "FileImport/UploadExcel";
export const GetBatchUploadList = "FileImport/GetBatchUploadList";
export const GetBatchRecordDetails = "FileImport/GetBatchRecordDetails?id={0}";
export const UpdateTargetCampaign = "FileImport/UpdateTargetCampaign";
export const GetDuplicateRecordListByBatchRecordBasicDetailsId = "FileImport/GetDuplicateRecordListByBatchRecordBasicDetailsId?id={0}";

export const UpdateDuplicateRecords = "FileImport/UpdateDuplicateRecords";
export const UpdateDuplicateEmailRecords = "FileImport/UpdateDuplicateEmailRecords";
export const UpdateDuplicateTransactionIdRecords = "FileImport/UpdateDuplicateTransactionIdRecords";

export const GetBatchRecordDonorDetails = "FileImport/GetBatchRecordDonorDetails?id={0}&dateOfUplaod={1}";
export const GetBatchRecordDonorDetailsList = "FileImport/GetBatchRecordDonorDetailsList";
export const DeleteBatchRecordDetails = "FileImport/DeleteBatchRecordDetails";



//Notification
export const AddEditNotificationDetails = "DonorInformation/AddEditNotificationDetails";
export const GetNotificationDetails = "DonorInformation/GetNotificationDetails?id={0}";

//Dashboard
export const GetAllDonationAmount = "Dashboard/GetAllDonationAmount";
export const GetDashboardList = "Dashboard/GetDashboardList";
export const ExportData = "Dashboard/ExportData";
export const PurgeData = "Dashboard/DataPurging2";