import React from 'react'

export default function ActionToggleButton(props) {
    
    const onChange = () => {
        if (props.onToggleChage) {
            props.onToggleChage(props.index);
        }        
    }


    return (
        <>
        {
            props.data ? 
            <div className={props.className} >
            <div className="flex items-center h-12">
                <div className="form-switch mr-4">
                    <input type="checkbox" id={'chk_' + props.index} className="sr-only" onChange={() => onChange()} value={props.value}
                    checked={props.data[props.index].isActive}
                    />
                    <label className="bg-[#757575]" htmlFor={'chk_' + props.index}>
                        <span className="bg-white shadow-sm" aria-hidden="true"></span>
                        <span className="sr-only">Switch label</span>
                    </label>
                </div>
                {props.labelRequired ? <>
                    {props.data[props.index].isActive ?  <>Activated</>  : <>Inactivated</> }
                </>

                   
                
                :  null  }
            </div>


        </div>

        :
        <div className={props.className} >
            <div className="flex items-center h-12">
                <div className="form-switch">
                    <input type="checkbox" id={'chk_' + props.index} className="sr-only" onChange={() => onChange()} 
                    
                    />
                    <label className="bg-[#757575]" htmlFor={'chk_' + props.index}>
                        <span className="bg-white shadow-sm" aria-hidden="true"></span>
                        <span className="sr-only">Switch label</span>
                    </label>
                </div>
            </div>

        </div>
        }
        </>
      
    )
}
