import { GeneratePartnerLink, GetAllDonationPurpose, GetAllPartner, GetPartnerLinkList, AddEditPartnerLink, ActiveInActivePartnerLinkById, DeletePartnerLink, GetPartnerLinkListById } from "../ApiEndPoints";
import { axiosGet, axiosPost, axiosGetMultiParams, axiosPostWithoutEncryption } from "../AxiosRequests";

export default class PartnerLinkService {
  
    async getPartnerNames(){
        return axiosGet(GetAllPartner);
    }
    async getDonationPurposeName(){
        return axiosGet(GetAllDonationPurpose);
    }
    async generatePartnerLink(request){
        return axiosGetMultiParams(GeneratePartnerLink,request);
    }
    async getPartnerLinkList(request){
        return axiosPost(GetPartnerLinkList, request);
    }
    async getPartnerLinkListById(request){
        return axiosGet(GetPartnerLinkListById,request);
    }
    async addEditPartnerLink(request){
        return axiosPost(AddEditPartnerLink, request);
    }
    async activeInActivePartnerLinkById(request){
        return axiosPost(ActiveInActivePartnerLinkById, request);
    }
    async deletePartnerLink(request){
        return axiosPost(DeletePartnerLink,request);
    }
}
