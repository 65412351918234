import React from 'react'

import "./CustomButton.css";


export const CustomButton = ({ id, className = "", width = 'auto', onClick, type = "primary", text = "Button", align = "left" }, props) => {
    return (
        <div className={`btnWrapper text-${align}`}>
            <button id={id || ""}
                style={{ minWidth: `${width}` }}
                className={`px-10 py-2 h-auto text-white focus:ring-4 font-[25px] focus:outline-none focus:ring-blue-300 font-medium  sm:w-auto text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ctrButton ${className} btn-${type}`}
                onClick={(e) => onClick(e)}>
                {text}
            </button>
        </div>
    )
}

export default CustomButton;