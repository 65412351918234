import React from 'react'
import ModalBasic from '../Model/FormModel'
import CancelButton from '../ui/CancelButton'
import CustomButton from '../ui/customButton/CustomButton'

export default function DuplicateEmailDetailModel(props) {
    return (
        <ModalBasic
            id="add-edit-strategic-member-modal"
            modalOpen={props.setDuplicateEmailDetailModel}
            setModalOpen={props.setOpenModal}
            title="Record Details"
            noCloseIcon={false}
        >
            <div className="main-body-section mb-7">

                <div className="grid grid-cols-12 gap-4 mb-2">
                    <div className="col-span-9 lg:col-span-12 md:col-span-12 w-full">
                        <div className="flex items-center">
                            <h3 class="font-bold text-[#757575] text-xl w-[250px]">
                                Date of Upload:
                            </h3>
                            <div className="col-span-12 text-xl">
                                <h1>{props.duplicateRecordList.dateOfUpload}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-9 lg:col-span-12 md:col-span-12 w-full">
                        <div className="flex items-center">
                            <h3 class="font-bold text-[#757575] text-xl w-[250px]">
                                Source of Upload:
                            </h3>
                            <div className="col-span-12 text-xl">
                                <h1>{props.duplicateRecordList.sourceName}</h1>
                            </div>

                        </div>
                    </div>
                    <div className="col-span-9 lg:col-span-12 md:col-span-12 w-full">
                        <div className="flex items-center">
                            <h3 class="font-bold text-[#757575] text-xl w-[250px]">
                                Number of Records:
                            </h3>
                            <div className="col-span-12 text-xl">
                                <h1>{props.duplicateRecordList.numberOfRecords}</h1>
                            </div>

                        </div>
                    </div>
                    <div className="col-span-9 lg:col-span-12 md:col-span-12 w-full">
                        <div className="flex items-center">
                            <h3 class="font-bold text-[#757575] text-xl w-[250px]">
                                Total Amount:
                            </h3>
                            <div className="col-span-12 text-xl">
                                <h1> {props.duplicateRecordList.totalAmount}</h1>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="bg-[#faccd4] text-[#ec052b] px-4 py-3 my-7 relative" role="alert">
                    <strong class="font-semibold text-2xl">Duplicate Email records identified..</strong>

                </div>

                <div className="frst first-letter:overflow-x-auto">
                    <table className="tbl w-full  bg-white shadow-lg rounded-sm border border-slate-200 ">
                        <thead className="table-bg  text-xl  font-semibold capitalize border-t  text-[#757575] border-b border-slate-200 duplicate">
                            <tr>
                                <th className="w-[12%] text-[20px] text-left border-r border-slate-200 tbl-th whitespace-nowrap">Transaction No.
                                </th>
                                <th className="w-[10%] text-[20px] text-left border-r border-slate-200 tbl-th whitespace-nowrap sec">NRIC
                                </th>
                                <th className="w-[29%] text-[20px] text-left border-r border-slate-200 tbl-th whitespace-nowrap">Email
                                </th>
                                <th className="w-[29%] text-[20px] text-left border-r border-slate-200 tbl-th whitespace-nowrap">Result
                                </th>
                                <th className="w-[20%] text-[20px] text-left border-r border-slate-200 tbl-th whitespace-nowrap">Actions
                                </th>
                            </tr>
                        </thead>
                        {/* <tbody className=" custom-tbody  text-[#757575] text-xl bg-duplicate">

                            {props.duplicateRecordList.duplicateNRICList.map((email, index) => {
                                return (
                                    <tr className="text-center border-b border-slate-200">
                                        <td className="w-[12%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">{email.transactionId}
                                        </td>
                                        <td className="w-[10%] p-1 px-4 text-[20px] text-left border-slate-200 border-r text-[#e7002b]">{email.nric}
                                        </td>
                                        <td className="w-[29%] p-1 px-4 text-[20px] text-left border-slate-200 border-r ">{email.donorEmail}
                                        </td>
                                        <td className="w-[29%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">{email.result}
                                        </td>
                                        <td className="w-[20%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">
                                            <div className='flex items-center justify-center'>
                                                <input
                                                    type="radio"
                                                    name={`${email.nric}`}
                                                    id={`${email.nric}/Id-${index}`}
                                                    className="w-6 mr-3"
                                                    value={email.isSelected}
                                                    onChange={(event) => props.handleChangeRadioButton(email, "DuplicateNRIC", event)}
                                                    checked={email.isSelected === true}
                                                />
                                                Main Household
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody> */}
                        <tr className='text-white rounded-sm border border-slate-200 '>

                        </tr>
                        <tbody className="  custom-tbody  text-[#757575] text-xl  bg-duplicate">

                            {props.duplicateRecordList.duplicateEmailList.map((email, index) => {
                                return (
                                    <tr className="text-center border-b border-slate-200">
                                        <td className="w-[12%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">{email.transactionId}
                                        </td>
                                        <td className="w-[10%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">{email.nric}
                                        </td>
                                        <td className="w-[29%] p-1 px-4 text-[20px] text-left border-slate-200 border-r text-[#e7002b]">{email.donorEmail}
                                        </td>
                                        <td className="w-[29%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">{email.result}
                                        </td>
                                        <td className="w-[20%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">
                                            <div className='flex items-center justify-center'>

                                                <input
                                                    type="radio"
                                                    name={`${email.donorEmail}`}
                                                    id={`${email.donorEmail}/Id-${index}`}
                                                    className="w-6 mr-3"
                                                    value={email.isSelected}
                                                    onChange={(event) => props.handleChangeRadioButton(email, "DuplicateEmail", event)}
                                                    checked={email.isSelected === true}
                                                />

                                                Main Household
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}



                        </tbody>
                        {/* <tr className='text-white rounded-sm border border-slate-200 '>ghgfhf

                        </tr>
                        <tbody className="  custom-tbody  text-[#757575] text-xl  bg-duplicate">

                            {props.duplicateRecordList.duplicateTransactionList.map((email, index) => {
                                return (
                                    <tr className="text-center border-b border-slate-200">
                                        <td className="w-[12%] p-1 px-4 text-[20px] text-left border-slate-200 border-r text-[#e7002b]">{email.transactionId}
                                        </td>
                                        <td className="w-[10%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">{email.nric}
                                        </td>
                                        <td className="w-[29%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">{email.donorEmail}
                                        </td>
                                        <td className="w-[29%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">{email.result}
                                        </td>
                                        <td className="w-[20%] p-1 px-4 text-[20px] text-left border-slate-200 border-r">
                                            <div className='flex items-center justify-center'>
                                                <input
                                                    type="radio"
                                                    name={`${email.transactionId}`}
                                                    id={`${email.transactionId}/Id-${index}`}
                                                    className="w-6 mr-3"
                                                    value={email.isSelected}
                                                    onChange={(event) => props.handleChangeRadioButton(email, "DuplicateTransaction", event)}
                                                    checked={email.isSelected === true}
                                                />
                                                Main Transaction
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody> */}
                    </table>
                    <div className="flex flex-wrap justify-between space-x-10 mt-12">
                        <CancelButton
                            text="Cancel"
                            width="100px"
                            onClick={props.setOpenModal}
                        />
                        <CustomButton
                            text="Submit"
                            width="100px"

                            onClick={() => props.updateDuplicateEmailRecords()}
                        />

                    </div>
                </div>
            </div>
        </ModalBasic >

    )
}