import React from 'react'
import { components } from 'react-select'

export default function Option(props) {
  return (
    <div>
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
      />{" "}
      <label>{props.label}</label>
    </components.Option>
  </div>
  )
}
