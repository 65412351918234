import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./navItems.css";

export const NavItems = ({ list }) => {
  const [UpdatedList, setUpdatedList] = useState(list);
  const [ShowChild, setShowChild] = useState(false);

  useEffect(() => {
    initList();
  }, []);

  const initList = () => {
    let newList = [...list];
    let tempList = newList.filter((item, index) => {
      item.id = index;
      item.expanded = false;
      return item;
    });
    setUpdatedList([...tempList]);
  };

  const updateList = (id) => {
    let newList = [...UpdatedList];
    let tempList = newList.filter((item, index) => {
      if (id == item.id) {
        item.expanded = !item.expanded;
      } else {
        item.expanded = false;
      }
      return item;
    });
    setUpdatedList([...tempList]);
  };

  const List = (props) => {
    return props.items.map((ele, index) => {
      return (
        <li
          key={`li_${index}`}
          onClick={(event) => {
            event.preventDefault();
            updateList(ele.id);
          }}
        >
          {ele.icon}
          <div className="linkWrapper">
            {ele.child.length > 0 ? (
              <>
                <a href={ele.link} title={ele.title}>
                  {ele.text}
                </a>
                {ele.child && (
                  <Children
                    items={ele.child}
                    state={ele.expanded ? `show` : `hide`}
                    index={`li_${index}`}
                  />
                )}
              </>
            ) : (
              <NavLink
                end
                to={ele.link}
                className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150`}
              >
                {ele.text}
              </NavLink>
            )}
            {/* {
                            ele.child && <Children items={ele.child} state={ele.expanded ? `show` : `hide`} index={`li_${index}`} />
                        } */}
            {/* <a href={ele.link} title={ele.title} >{ele.text}</a>
                        {
                            ele.child && <Children items={ele.child} state={ele.expanded ? `show` : `hide`} index={`li_${index}`} />
                        } */}
          </div>
        </li>
      );
    });
  };

  const Children = (props) => {
    return props.items.length > 0 ? (
      <ul key={props.index} className={props.state}>
        {props.items.map((ele, index) => {
          return (
            <div className="linkWrapper">
              <li key={`li_2_${index}`}>
                {/* <a href={ele.link} title={ele.title}>{ele.text}</a> */}
                <NavLink
                  end
                  to={ele.link}
                  className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150`}
                >
                  {ele.text}
                </NavLink>
              </li>
            </div>
          );
        })}
      </ul>
    ) : (
      <></>
    );
  };

  return (
    <div className="navItems-wrapper">
      <ul>{<List items={UpdatedList} />}</ul>
    </div>
  );
};

export default NavItems;
