import AccountManagement from "../pages/createAccount/AccountManagement";
import Dashboard from "../pages/dashboard/Dashboard";
import Notification from "../pages/notification/Notification";
import PartnerLinks from "../pages/partnerLinks/PartnerLinks";
import BatchUpload from "../pages/Record/BatchUpload";

const ComponentNavigation = [
  { path: 'Dashboard', title: 'Dashboard', component: Dashboard, hasParams: false },
  { path: 'AccountManagement', title: 'AccountManagement', component: AccountManagement, hasParams: false },
  { path: 'PartnerLinks', title: 'PartnerLinks', component: PartnerLinks, hasParams: false },
  { path: 'BatchUpload', title: 'Batch Upload', component: BatchUpload, hasParams: false },
  { path: 'Notification', title: 'Notification Bar', component: Notification, hasParams: false }

];
export default ComponentNavigation;
