import { AddEditNotificationDetails,GetNotificationDetails} from "../ApiEndPoints";
import { axiosPost,axiosGet } from "../AxiosRequests";


export default class NotificationServices {
  
    async addEditNotificationDetails(request){
         return axiosPost(AddEditNotificationDetails,request);
     }
    async getNotificationDetails(request){
        return axiosGet(GetNotificationDetails,request);
    }   
}
