import React, { Component } from "react";
import image from "../../assets/images/default-image.jpg";
import imagetemp from "../../assets/images/logo.png";
import ActionContainer from "../../components/ui/ActionTableComponent/actionContainer";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import MultiSelectDropdown from "../../components/dropdown/MultiSelectDropdown";
import Option from "../../components/dropdown/multiSelectDropdownOption/MultiSelectOption";
import SwalServices from "../../services/swalServices/SwalServices";
import AccountManagementServices from "../../services/axiosServices/apiServices/AccountManagementServices";
import ImageCropperWithUpload from "../../components/ImageUpload/UploadImage";
import TextInput from "../../components/common/pageLoader/TextInput";
import CustomButton from "../../components/ui/customButton/CustomButton";
import Table from "../../components/table/Table";
import ActionEditButton from "../../components/ui/ActionTableComponent/ActionEditButton";
import { CommonValidationMessages } from "../../utils/Messages";
import ValidationText from "../../utils/validation/ValidationText";
import ActionToggleButton from "../../components/ui/ActionTableComponent/ActionToggleButton";
import ActionDeleteButton from "../../components/ui/ActionTableComponent/ActionDeleteButton";
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export default class AccountManagement extends Component {
  constructor(props) {
    super(props);
    this.adminServices = new AccountManagementServices();
    this.swalServices = new SwalServices();
    this.state = {
      uploading: false,
      userImage: image,
      roles: [],
      selectedValues: [1],

      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        SearchBySGTechDesignation: 0,
        SearchByLeadershipStatus: 0,
      },
      allAdminList: [],
      // allAdminList: {},
      totalResultes: 0,
      accountDetails: {
        adminUserId: 0,
        userName: "",
        email: "",
        password: "",
        repassword: false,
        roleId: 0,
        imgPath: "",
        isActive: 0,
        selectedRoleIds: [],
      },
      donationPurposeArr: [
        {
          value: 1,
          label: "$10",
        },
        {
          value: 2,
          label: "$50",
        },
        {
          value: 3,
          label: "$100",
        },
        {
          value: 4,
          label: "Other Amount",
        },
      ],
      validationRule: {
        email: [
          {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "valid email address"
            ),
          },
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "email address"
            ),
          },
        ],
        userName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "user name"
            ),
          },
        ],
        roleId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "role"
            ),
          },
        ],
        password: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "password"
            ),
          },
        ],
      },
      validationRule1: {


        email: [
          {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "valid email address"
            ),
          },
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "email address"
            ),
          },
        ],
        userName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "user name"
            ),
          },
        ],
        roleId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "role"
            ),
          },
        ],


      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  validatePasswordField = (key) => {
    const newValidState = validate(
      key,
      this.state.accountDetails,
      this.state.validationRule1,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.accountDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  savingData = () => {
    if (this.state.accountDetails.adminUserId === 0) {
      if (this.isValidateAllFields()) {
        this.addEditAccountDetails();
      }
    }
    else if (this.state.accountDetails.adminUserId > 0) {
      if (this.isvalidateAllPasswordFields()) {
        this.addEditAccountDetails();

      }
    }
  }

  isvalidateAllPasswordFields = () => {
    const newValidState = isValidForm(
      this.state.accountDetails,
      this.state.validationRule1,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.accountDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  btnClicked = (event) => {
    event.preventDefault(); // stops page from refreshing. remove if form submit
    this.setState({ selectedValues: [5, 3] });
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    if (this.onPageChange) {
      this.onPageChange();
    }
    this.getAdminAccountList(newPagination);
  };

  onToggleChage = (index) => {
    let accountData = this.state.accountDetails;
    let request = this.state.allAdminList[index];
    if (request.isActive === false) {
      request.isActive = true;
    } else if (request.isActive === true) {
      request.isActive = false;
    }
    this.activeInActiveAdmin(request);
    //this.setState({accountDetails : request});
  };
  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.accountDetails;
    detail[name] = value;
    this.setState({ accountDetails: { ...detail } });
  }

  handleChangeInDropdown = (selected, drpIdentity) => {
    var details = this.state.accountDetails;

    if (drpIdentity === "Role") {
      details.roleId = selected.value;
      this.validateField("roleId");
    }

    this.setState({ accountDetails: details });
  };

  // uploadUserImage = () => {
  //   this.setState({ uploading: true }, () => {
  //     this.setState({ userImage: imagetemp }, () => {
  //       setTimeout(() => {
  //         this.setState({ uploading: false });
  //       }, 2000);
  //     });
  //   });
  // };

  activeInActiveAdmin = (request) => {
    this.adminServices.activeInActiveAdmin(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        //this.swalServices.Success("Admin Activated");
        this.getAdminAccountList();
      }
    });
  };
  saveMainImage = (file, keyId, imageIdentity) => {
    file.storagePath = imageIdentity;
    this.adminServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("Image Uploaded");
        let detail = this.state.accountDetails;
        if (file.storagePath === "profile") {
          detail["imgPath"] = response.fileName;
          this.setState({ accountDetails: { ...detail } });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  resetFields = () => {
    var acc = this.state.accountDetails;
    acc.adminUserId = 0;
    acc.userName = "";
    acc.email = "";
    acc.password = "";
    acc.roleId = 0;
    acc.imgPath = "";
    acc.isActive = "";
    acc.selectedRoleIds = 0;
    this.setState({ accountDetails: acc });
  };

  addEditAccountDetails = () => {
    // let isValid = this.isValidateAllFields();
    // if (isValid) {
    this.adminServices
      .addEditAccountDetails(this.state.accountDetails)
      .then((response) => {

        if (
          response.statusCode === 200 &&
          response.responseItem != null &&
          response.responseItem.responseContent.hasError !== true
        ) {
          this.resetFields();
          this.swalServices.Success("Admin Details inserted successfully.");
          this.getAdminAccountList();
        } else {
          this.swalServices.Error("Email address already exists.");
        }
      });
  };

  getAdminAccountList = () => {
    this.adminServices
      .getAdminAccountList(this.state.pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            allAdminList: response.responseItem.responseContent.itemList,
          });
          this.setState({
            totalResultes: response.responseItem.responseContent.totalCount,
          });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  resetValid = () => {
    let resetValidation = {
      ...this.state.validState,
      isValid: false,
      error: {},
    };
    this.setState({ validState: resetValidation });
  };
  getAdminDetailsById = (id) => {
    this.adminServices.getAdminDetailsById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          accountDetails: response.responseItem.responseContent,
        });
        this.resetValid();
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  onEdit = (value, index) => {
    var scroll = document.getElementById("ceateEditAccount");
    scroll.scrollIntoView();
    this.getAdminDetailsById(value);
  };

  getAllRoles = () => {
    this.adminServices.getAllroles().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ roles: response.responseItem.responseContent });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  onDelete = (adminUserId) => {
    let request = {
      id: adminUserId,
      deletedBy: 1,
    };
    this.adminServices.deleteAdminUser(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Admin User Deleted");
        this.getAdminAccountList();
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.pageSize = 10;
    detail.searchText = value;
    this.setState({ pagination: { ...detail } });
    this.getAdminAccountList();
  };

  componentDidMount() {
    this.getAllRoles();
    this.getAdminAccountList();
  }
  // onEdit = (value, index) => {
  //   let editEmailDetails = [...this.state.emailHistoryList];
  //   this.setState({ redirect: '/CreateEmail/mailId?=' + editEmailDetails[index].mailId });
  // };
  actions = (element, index, value) => {
    return element !== "adminUserId" ? null : (
      // <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
      //   <div className="flex items-center relative">

      <ActionContainer>
        <ActionEditButton
          value={value}
          id={index}
          index={index}
          onActionClick={this.onEdit}
          text=""
        />

        <ActionToggleButton
          labelRequired={true}
          key={index + "toggle"}
          className="mr-2"
          index={index}
          value={this.state.accountDetails.isActive}
          // toggle={this.state.toggle}
          data={this.state.allAdminList}
          onToggleChage={this.onToggleChage.bind(this)}
        />
        <ActionDeleteButton
          value={value}
          id={index}
          index={index}
          onActionClick={this.onDelete}
          text="Delete"
        />
      </ActionContainer>
      //   </div>
      // </td>
    );
  };

  render() {
    return (
      <div className="main-body-section">
        <div class="heading-part py-3 mb-7 ">
          <div class="flex items-center justify-between">
            <div className="left-tiitle ">
              <h3 class="font-bold  text-[#757575] text-4xl">
                Account Management
              </h3>
            </div>
          </div>
        </div>
        <div className="bg-white border border-[#c9c9c9] mb-10">
          <div class="heading-part px-7 py-4 border-b border-[#c9c9c9]">
            <div class="flex items-center justify-between">
              <div className="left-tiitle">
                <h4 class="small-title font-bold text-[#757575]">
                  Create/Edit Account
                </h4>
              </div>
            </div>
          </div>
          <div className="p-7" id="ceateEditAccount">
            <div className="grid grid-cols-12 gap-3 w-full ">
              <div className="xl:col-span-2 lg:col-span-2 col-span-12">
                {/* <CustomFileUpload
                                type={"image"}
                                loadingText={"Please wait"}
                                loading={this.state.uploading}
                                buttonText={"Upload Photo"}
                                width={"200px"}
                                alignButton={'center'}
                                src={this.state.userImage}
                                value={this.state.accountDetails.profilePic}
                                onUpload={this.uploadUserImage} /> */}
                <div className="2xl:col-span-3 lg:col-span-3 col-span-12 ">
                  <ImageCropperWithUpload
                    key="Main"
                    height={550}
                    width={550}
                    isCheckValidation={true}
                    MaxFileSize={2097152}
                    MaxFileSizeMessage={"2MB"}
                    onSaveImage={this.saveMainImage}
                    imageIdentity="profile"
                    intitImage={this.state.accountDetails.imgPath}
                    aspactRatio={550 / 550}
                    uploadBtn={"Upload Photo"}
                  //dimensionText={"(Dimension: 550px by 260px Maximum Size: 2MB)"}
                  />
                </div>
              </div>
              <div className="xl:col-span-10 lg:col-span-10 col-span-12 lg:pl-24">
                <div className="grid grid-cols-12 gap-6 w-full ">
                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                      <div className="xl:col-span-5 lg:col-span-5 col-span-12">
                        <h2 className="text-xl text-[#757575]">
                          Email
                          <span className="text-[#C00000]">*</span>
                        </h2>
                        <TextInput
                          className="w-full outline-none appearance-none"
                          placeholder="asd"
                          type="text"
                          name="email"
                          value={this.state.accountDetails.email}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField}
                          validState={this.state.validState}
                          error={this.state.validState.error.email}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                      <div className="xl:col-span-5 lg:col-span-5 col-span-12">
                        <h2 className="text-xl text-[#757575]">
                          Name
                          <span className="text-[#C00000]">*</span>
                        </h2>
                        <TextInput
                          className="w-full outline-none appearance-none"
                          placeholder="asd"
                          type="text"
                          name="userName"
                          value={this.state.accountDetails.userName}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField}
                          validState={this.state.validState}
                          error={this.state.validState.error.userName}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <CustomTextbox
                                id={'txtEmail'}
                                label="Email"
                                name="email"
                                required
                                value={this.state.accountDetails.email}
                                onChange={this.handleChange.bind(this)} /> */}
                  {/* <CustomTextbox
                                id={'txtName'}
                                label="Name"
                                required
                                name='userName'
                                value={this.state.accountDetails.userName}
                                onChange={this.handleChange.bind(this)}
                            /> */}
                  {/* <CustomDropdown
                                id={'ddlRole'}
                                selectedItems={this.state.selectedValues}
                                label="Role"
                                required
                                list={this.state.list}
                                onChange={items => this.handleChangeInDropdown(items)} 
                                
                                /> */}
                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                      <div className="xl:col-span-5 lg:col-span-5 col-span-12">
                        <h2 className="text-xl text-[#757575]">
                          Role
                          <span className="text-[#C00000]">*</span>
                        </h2>
                        <div className="">
                          <MultiSelectDropdown
                            options={this.state.roles}
                            //isMulti
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                            }}
                            onChange={this.handleChangeInDropdown.bind(this)}
                            allowSelectAll={false}
                            value={this.state.roles.filter(
                              (item) =>
                                item.value === this.state.accountDetails.roleId
                            )}
                            drpIdentity="Role"
                          />
                          <div>
                            <ValidationText
                              error={this.state.validState.error.roleId}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.accountDetails.adminUserId === 0 ?
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                      <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="xl:col-span-5 lg:col-span-5 col-span-12">
                          <h2 className="text-xl text-[#757575]">Password
                            <span className="text-[#C00000]">*</span>
                          </h2>
                          <TextInput
                            className="w-full outline-none appearance-none"
                            placeholder="asd"
                            type="password"
                            name="password"
                            value={this.state.accountDetails.password}
                            handleChange={this.handleChange.bind(this)}
                            validateField={this.validateField}
                            error={this.state.validState.error.password}
                            validState={this.state.validState}

                          />
                        </div>
                      </div>
                    </div>
                    : null}

                  <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="flex items-center justify-end">
                      <CustomButton
                        text="Save"
                        width="100px"
                        onClick={() => this.savingData()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-4 pb-7">
          <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#757575]">
            {/* {`Total:${this.state.totalResultes}`} */}
          </h2>
          <div className="col-end-7 col-span-2 grid grid-cols-3 items-center">
            <caption className="text-[#757575] text-xl">Search</caption>
            {/* <input className="col-span-2 text-lg font-bold  h-[40px] w-full text-[#757575]"/> */}
            <input
              id="default"
              className="col-span-2 text-lg font-bold  h-[55px] w-full text-[#757575] border-white"
              type="text"
              onChange={(e) => this.searchByName(e.target.value)}
            />
          </div>
        </div>
        <div className="account-list-tble">
          <Table
            columns={[
              { name: "userName", title: "Name" },
              { name: "email", title: "Email" },
              { name: "roleName", title: "Role" },
              { name: "adminUserId", title: "Actions" },
            ]}
            rows={this.state.allAdminList}
            isLoading={this.state.isLoading}
            sortingColumns={["userName", "email"]}
            pagination={this.state.pagination}
            totalResultes={this.state.totalResultes}
            totalCount={this.state.totalResultes}
            setPagination={this.setPagination.bind(this)}
            customScope={[
              {
                column: "adminUserId",
                renderTableData: this.actions.bind(this),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}
