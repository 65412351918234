import React from 'react'

const ValidationText = (props) => {
    return (
        props.error?
        <div className="text-xs text-[#c00000]">{props.error}</div>
        :null
    )
}

export default ValidationText;
