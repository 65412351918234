import React from "react";
import logo from "../../../assets/images/logo.png";
import NavItems from "../navItems/NavItems";

import "./Sidebar.css";

export const Sidebar = ({ floating, navState = true }) => {
  let list = [
    {
      link: "/Dashboard",
      title: "Dashboard",
      text: "Dashboard",
      icon: (
        <span>
          <i className="fa fa-dashboard"></i>
        </span>
      ),
      child: [],
    },
    {
      link: "/AccountManagement",
      title: "Account Management",
      text: "Account Management",
      icon: (
        <span>
          <i className="fa fa-user"></i>
        </span>
      ),
      child: [],
    },
    {
      link: "/BatchUpload",
      title: "Batch Upload",
      text: "Batch Upload",
      icon: (
        <span>
          <i className="fa fa-file-text-o"></i>
        </span>
      ),
      child: [],
    },
    {
      link: "/PartnerLinks",
      title: "Partner Links",
      text: "Partner Links",
      icon: (
        <span>
          <i className="fa fa-line-chart"></i>
        </span>
      ),
      child: [],
    },
    {
        link: "/Notification",
        title: "Notification Bar",
        text: "Notification Bar",
        icon: (
          <span>
            <i className="fa fa-bell-o"></i>
          </span>
        ),
        child: [],
      },
  ];

  return (
    <div className={`sidebar-wrapper ${navState ? `open` : `close`}`}>
      <div className={`logo-wrapper ${navState ? `open` : `close`}`}>
        <img src={logo} className="logo-img" />
      </div>
      <NavItems list={list} />
    </div>
  );
};

export default Sidebar;
