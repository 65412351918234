import React from 'react'

export default function ActionDeleteButton(props) {
  return (
    <button className='action-btn right-icon' 
        onClick={() => props.onActionClick(props.value)}
    >
    <i className="fa fa-trash-o fa-2x" aria-hidden="true"></i>
  </button>
  )
}
