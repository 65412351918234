import React from 'react'
import MultiSelectDropdown from '../dropdown/MultiSelectDropdown'
import ModalBasic from '../Model/FormModel'
import CustomButton from '../ui/customButton/CustomButton'
import CancelButton from '../ui/CancelButton'
import PageLoader from '../common/pageLoader/PageLoader'
import Table from '../table/Table'

export default function BatchUploadDetailsModal(props) {

    return (
        <ModalBasic
            id="add-edit-strategic-member-modal"
            modalOpen={props.setBatchUploadDetailsModal}
            setModalOpen={props.setOpenModal}
            title="Batch Upload Details"
            noCloseIcon={false}
            isClose={props.isClose}
        >
            {props.isLoadingForCampaign === true ? <PageLoader /> : null}
            <div className="pt-12">
            <Table
              columns={[
                { name: "time", title: "Time" },
                { name: "donation", title: "Donation" },
                { name: "id", title: "Transaction ID" },
                { name: "recipientCampaign", title: "Recipient Campaign" },
                { name: "donorName", title: "Donor Name" },
                { name: "donorEmail", title: "Donor Email" },
                { name: "nric", title: "NRIC" },
                { name: "anonymous", title: "Anonymous" },
                { name: "address", title: "Address" },
              ]}
              rows={props.rows}
              isLoading={props.isLoading}
              sortingColumns={[""]}
              pagination={props.pagination}
              totalResultes={props.totalResultes}
              totalCount={props.totalResultes}
              setPagination={(pagination) => props.setModalPagination(pagination)}
            />
          </div>
        </ModalBasic >

    )
}
