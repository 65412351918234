import React from "react";
import ValidationText from "../../../utils/validation/ValidationText";

const TextInput = (props) => {

    return (
        <div className="relative">
            <input
                className={props.className}
                id={props.id}
                type={props.type}
                name={props.name}
                value={props.value}
                onChange={(event) => props.handleChange(event)}
                onBlur={() => props.validateField(props.name)}
            />
            <div className="absolute">
                <ValidationText error={props.error} />
            </div>
        </div>
    );
}
export default TextInput;