import React from "react";
export default function ActionContainer(props){
    return (
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
            <div className="flex items-center">
                {props.children}
            </div>
        </td>
    )
  }
  