
export default function CancelButton({ id, className = "", width = 'auto', onClick, type = "primary", text = "Button", align = "left" }, props) {
    return (
        <button className="btn-sm btn-gray text-lg text-white"
            onClick={() => onClick()}
        >
            {text}

        </button>
    )
}
