import { ExportData, GetAllDonationAmount, GetDashboardList, PurgeData } from "../ApiEndPoints";
import { axiosGet, axiosPost, axiosPostFileAuthorizeblob } from "../AxiosRequests";



export default class DashboardServices {

    async getAllDonationAmount() {
        return axiosGet(GetAllDonationAmount);
    }

    async getDashboardList(request) {
        return axiosPost(GetDashboardList,request);
    }
    async exportData(request) {
        return axiosPostFileAuthorizeblob(ExportData, request);
    }
    async purgeData(request){
        return axiosPost(PurgeData,request);
    }
}
