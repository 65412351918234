import React from 'react'

import NavButton from '../navButton/NavButton';
import UserSection from '../userSection/UserSection';
import "./Header.css";



export const Header = ({ navState = "open", toggleNav }) => {
    return (
        <div className='header-wrapper'>
            
            <div className='header-elements'>
                {/* <NavButton onClick={toggleNav} /> */}
                <UserSection loggedIn={true} />
            </div>
        </div>
    )
}

export default Header;