import React from 'react'
import '../../../index.css'

export default function ActionCopyUrl(props) {
  return (
    <button className='action-btn right-icon' 
        onClick={() => props.onActionClick(props.value)}
    >
    <u><a href="#">copy URL</a></u> &nbsp;&nbsp;
  </button>
  )
}
