import { AddFile, DeleteBatchRecordDetails, DownloadGiveAsiaTemplete, DownloadGivingSGTemplete, DownloadMasterTemplete, GetAllDonationPurpose, GetAllDonationPurposeBatchUpload, GetBatchRecordDetails, GetBatchRecordDonorDetails, GetBatchRecordDonorDetailsList, GetBatchUploadList, GetDuplicateRecordListByBatchRecordBasicDetailsId, UpdateDuplicateEmailRecords, UpdateDuplicateRecords, UpdateDuplicateTransactionIdRecords, UpdateTargetCampaign } from '../ApiEndPoints';
import { axiosGet, axiosGetMultiParams, axiosPost, axiosPostFileAuthorizeblob, axiosPostWithoutEncryption } from '../AxiosRequests';

export default class BatchUploadServices {

    async downloadGiveAsiaTemplete(request) {
        return axiosPostFileAuthorizeblob(DownloadGiveAsiaTemplete, request);
    }
    async downloadGivingSGTemplete(request) {
        return axiosPostFileAuthorizeblob(DownloadGivingSGTemplete, request);
    }
    async downloadMasterTemplete(request) {
        return axiosPostFileAuthorizeblob(DownloadMasterTemplete, request);
    }
    async addFile(request) {
        let formData = new FormData();
        formData.append("file", request.file);
        formData.append("dateOfUpload", request.dateOfUpload);
        formData.append("fileName", request.fileName);
        formData.append("sourceId", request.sourceId);
        formData.append("batchSource", request.batchSource);
        formData.append("fileTypeExtension", request.fileTypeExtension);
        return axiosPostWithoutEncryption(AddFile, formData, true);
    }
    async getBatchUploadList(request) {
        return axiosPost(GetBatchUploadList, request);
    }

    async getBatchRecordDetails(request) {
        return axiosGet(GetBatchRecordDetails, request);
    }

    async getDonationPurposeName() {
        return axiosGet(GetAllDonationPurposeBatchUpload);
    }

    async updateTargetCampaign(request) {
        return axiosPost(UpdateTargetCampaign, request);
    }

    async getDuplicateRecordListByBatchRecordBasicDetailsId(request) {
        return axiosGet(GetDuplicateRecordListByBatchRecordBasicDetailsId, request);
    }

    //duplicate records API begins
    async updateDuplicateRecords(request) {
        return axiosPost(UpdateDuplicateRecords, request);
    }
    async updateDuplicateEmailRecords(request) {
        return axiosPost(UpdateDuplicateEmailRecords, request);
    }
    async updateDuplicateTransactionIdRecords(request) {
        return axiosPost(UpdateDuplicateTransactionIdRecords, request);
    }
    //duplicate records API end

    async getBatchRecordDonorDetails(request) {
        return axiosGetMultiParams(GetBatchRecordDonorDetails, request);
    }
    async getBatchRecordDonorDetailsList(request) {
        return axiosPost(GetBatchRecordDonorDetailsList, request);
    }
    async deleteBatchRecordDetails(request) {
        return axiosPost(DeleteBatchRecordDetails, request);
    }
}