import React, { Component } from 'react'
import TextInput from '../../components/common/pageLoader/TextInput'
import MultiSelectDropdown from '../../components/dropdown/MultiSelectDropdown'
import Table from '../../components/table/Table'
import Option from "../../components/dropdown/multiSelectDropdownOption/MultiSelectOption";
import SwalServices from "../../services/swalServices/SwalServices";
import ActionContainer from '../../components/ui/ActionTableComponent/actionContainer'
import ActionEditButton from '../../components/ui/ActionTableComponent/ActionEditButton'
import CustomButton from '../../components/ui/customButton/CustomButton'
import ValidationText from '../../utils/validation/ValidationText'
import ActionToggleButton from '../../components/ui/ActionTableComponent/ActionToggleButton'
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../utils/Messages";
import ActionDeleteButton from '../../components/ui/ActionTableComponent/ActionDeleteButton'
import PartnerLinkService from '../../services/axiosServices/apiServices/PartnerLinkService'
import moment from "moment";
import ActionCopyUrl from '../../components/ui/ActionTableComponent/ActionCopyUrl';

export default class PartnerLinks extends Component {

    constructor(props) {
        super(props);
        this.partnerLinkServices = new PartnerLinkService();
        this.swalServices = new SwalServices();
        this.state = {
            selectedValues: [1],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                SearchDoantion: "",
                searchCampaign: "",
                partnerName: '',
            },
            allPartnerLinkList: [],
            totalResultes: "",
            isLoading: false,
            // partnerNames: [],
            donationNames: [],
            partnerId: "",
            // donationPurposeId: "",
            donationPurposeName: "",
            generateLinkRequestDetails: {
                partnerId: "",
                // donationPurposeId: "",
                partnerName: "",
                donationPurposeName: "",
            },
            /*
            addEditPartnerLink: {
                partnerLinkId: "",
                partnerId: "",
                donationPurposeId: "",
                url: "",
            },
            */
            partnerLinkDetails: {
                partnerLinkId: 0,
                id: 0,
                donationCount: "",
                url: "",
                // donationPurposeId: 0,
                donationPurposeName: "",
                partnerId: 0,
                partnerName: "",
                isActive: "",
                createdDate: "",
                createdBy: "",
            },
            validationRule: {
                partnerName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'partner name'),
                    },
                ],
                donationPurposeName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'donation purpose name'),
                    },
                ],
            },
            validState: {
                isValid: true,
                error: {}
            },
            url: "",
        }
    }
    activeInActivePatner = (request) => {
        this.partnerLinkServices.activeInActivePatner(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.generateLinkRequestDetails();
            }
        });
    };
    onToggleChage = (index) => {
        let accountData = this.state.generateLinkRequestDetails;
        let request = this.state.allAdminList[index];
        if (request.isActive === false) {
            request.isActive = true;
        } else if (request.isActive === true) {
            request.isActive = false;
        }
        this.activeInActivePatner(request);
        this.setState({ generateLinkRequestDetails: request });
    };

    resetValid = () => {
        let resetValidation = {
            ...this.state.validState,
            isValid: false,
            error: {}
        }
        this.setState({ validState: resetValidation })
    }
    getPartnerLinkListById = (id) => {
        this.partnerLinkServices.getPartnerLinkListById(id).then((response) => {

            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ url: response.responseItem.responseContent.url });
                this.setState({ partnerLinkDetails: response.responseItem.responseContent });
                this.setState({ generateLinkRequestDetails: response.responseItem.responseContent });
                this.resetValid()
            } else {
                this.swalServices.Error(response.message);
            }
        });
    };

    onEdit = (value, index) => {
        var scroll = document.getElementById("partnerDiv")
        scroll.scrollIntoView();
        this.getDonationName();
        this.getPartnerLinkListById(value);
    }

    deletePartnerLink = () => {
        this.partnerLinkServices.deletePartnerLink(this.state.partnerLinkDetails, this.state.partnerLinkDetails.id).then((response) => {
            this.swalServices.Success("Partner Link deleted successfully.");
            this.getPartnerLinkList();
            this.resetFields();
        });
    };

    onDelete = (value, index) => {
        this.getPartnerLinkListById(value);
        this.state.partnerLinkDetails.id = value;
        this.deletePartnerLink();
    }

    onCopyUrl = (value, index) => {
        let list = [...this.state.allPartnerLinkList];
        let ca = list.find((item) => item.partnerLinkId === value);
        var textField = document.createElement('textarea')
        textField.innerText = ca.url
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        this.swalServices.Toaster('Url Copied');
    }

    getPartnerName = () => {
        this.partnerLinkServices.getPartnerNames().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ partnerNames: response.responseItem.responseContent });
            }
            this.setState({ isLoading: false });
        });
    }
    getDonationName = () => {
        this.partnerLinkServices.getDonationPurposeName().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                var details = response.responseItem.responseContent;
                let d = details.findIndex((x) => x.campaignName === 'a0A28000002YMd1EAG');
                if (d >= 0) {
                    details[d].label = 'General Purpose';
                }
                this.setState({ donationNames: details });
            }
            this.setState({ isLoading: false });
        });
    }
    generateLink = () => {
        let reqPrm = [this.state.generateLinkRequestDetails.partnerName, this.state.generateLinkRequestDetails.donationPurposeName, false];
        this.partnerLinkServices.generatePartnerLink(reqPrm).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ url: response.responseItem.responseContent })

            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }
    resetFields = () => {
        var par = this.state.partnerLinkDetails
        par.partnerLinkId = 0;
        par.partnerId = 0;
        par.donationPurposeName = '';
        par.url = '';
        par.isActive = '';
        par.partnerName = '';
        this.setState({ partnerLinkDetails: par });

        var url = this.state.url = '';
        this.setState({ url: url });
    }

    addEditPartnerLink = () => {
        let isValid = this.isValidateAllFields();
        if (isValid) {
            let request = this.state.partnerLinkDetails;
            request.url = this.state.url;
            this.partnerLinkServices.addEditPartnerLink(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
                    this.resetFields();
                    this.swalServices.Success("Partner Link Details saved successfully.");
                    this.getPartnerLinkList();
                } else {
                    this.swalServices.Error("Partner link already exists.");
                }
            });
        }
    };

    getPartnerLinkList = () => {
        this.partnerLinkServices.getPartnerLinkList(this.state.pagination).then((response) => {

            if (response.statusCode === 200 && response.responseItem != null) {
                let newAllPartnerLinkList =
                    response.responseItem.responseContent.itemList;
                newAllPartnerLinkList.map((key, index) => {
                    key.createdDate = moment(key.createdDate).format("DD MMM YYYY");
                    key.donationAmount = key.donationAmount ? "$" + parseFloat(key.donationAmount).toFixed(2) : "-";
                    // if (key.donationAmount) {
                    //     let test = key.donationAmount;
                    //     let test1 = String(test).split(".");
                    //     key.donationAmount = "$" + (test1[1] == null || undefined ? parseFloat(key.donationAmount).toFixed(1) : parseFloat(key.donationAmount).toFixed(2));
                    // }
                    // else {
                    //     key.donationAmount = "-";
                    // }
                });
                this.setState({ allPartnerLinkList: newAllPartnerLinkList });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount })

            }
            else {
                this.swalServices.Error(response.message);
            }
        });
    }

    actions = (element, index, value) => {
        return element !== "partnerLinkId" ? null : (
            <ActionContainer>
                <ActionCopyUrl
                    value={value}
                    id={index}
                    index={index}
                    onActionClick={this.onCopyUrl.bind(this)}
                />
                <ActionEditButton
                    value={value}
                    id={index}
                    index={index}
                    onActionClick={this.onEdit.bind(this)}
                // text="copy URL"
                />

                <ActionDeleteButton
                    className="mr-2"
                    value={value}
                    id={index}
                    index={index}
                    onActionClick={this.onDelete.bind(this)}
                />
            </ActionContainer>
        );
    };
    status = (element, index, value) => {
        return element !== "status" ? null : (
            <ActionContainer>
                <ActionToggleButton
                    labelRequired={true}
                    key={index + "toggle"}
                    className="mr-2"
                    index={index}
                    value={this.state.partnerLinkDetails.isActive}
                    // toggle={this.state.toggle}
                    data={this.state.allPartnerLinkList}
                    onToggleChage={this.onToggleChage.bind(this)}
                />
            </ActionContainer>
        );
    };

    validateField = (key) => {
        const newValidState = validate(key, this.state.partnerLinkDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.partnerLinkDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }

    btnClicked = (event) => {
        event.preventDefault(); // stops page from refreshing. remove if form submit
        this.setState({ selectedValues: [5, 3] });
    };

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        if (this.onPageChange) {
            this.onPageChange();
        }
        this.getPartnerLinkList(newPagination);
    };

    onToggleChage = (index) => {
        let partnerLinkData = this.state.partnerLinkDetails;
        let request = this.state.allPartnerLinkList[index];
        if (request.isActive === false) {
            request.isActive = true;
        }
        else if (request.isActive === true) {
            request.isActive = false;
        }
        this.activeInActivePartnerLinkById(request);
        //this.setState({accountDetails : request});
    };

    handleChangeInDropdown = (selected, drpIdentity) => {
        var details = this.state.partnerLinkDetails;

        if (drpIdentity === "PartnerName") {
            details.partnerName = selected.label;
            this.validateField("partnerName");
        }
        if (drpIdentity === "DonationName") {
            details.donationPurposeName = selected.label;
            this.validateField("donationPurposeName");
        }
        this.setState({ generateLinkRequestDetails: details });
        this.setState({ partnerLinkDetails: details });
    };

    activeInActivePartnerLinkById = (request) => {
        this.partnerLinkServices.activeInActivePartnerLinkById(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.getPartnerLinkList();
            }
        })
    }
    componentDidMount() {
        this.getPartnerName();
        this.getDonationName();
        this.getPartnerLinkList();
    }

    searchByText = (value) => {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        detail.pageSize = 10;
        detail.searchText = value;
        this.setState({ pagination: { ...detail } });
        this.getPartnerLinkList();
    }

    /// Handle the team input changes
    handleChange(key, value) {
        let searchPram = this.state.pagination;
        searchPram[key] = value;
        this.setState({ pagination: { ...searchPram } });
    }
    handleTextChange(key, value) {
        let searchPram = this.state.partnerLinkDetails;
        searchPram[key] = value;
        this.setState({ partnerLinkDetails: { ...searchPram } });
        this.setState({ generateLinkRequestDetails: { ...searchPram } });
    }

    applyFilter = () => {
        this.getPartnerLinkList();
    }

    render() {
        return (
            <>
                <div className="main-body-section">
                    <div class="heading-part py-3">
                        <div class="flex items-center justify-between">
                            <div className="left-tiitle mb-5 ">
                                <h3 class="font-bold  text-[#757575] text-4xl">
                                    Campaign Lists
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white border border-[#c9c9c9] mb-10">

                        <div class="heading-part px-7 py-4 border-b border-[#c9c9c9]">
                            <div class="flex items-center justify-between">
                                <div className="left-tiitle">
                                    <h4 class="small-title font-bold text-[#757575]">
                                        Create URL
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="px-10 py-10 pt-7">
                            <div className="grid grid-cols-12 gap-6 w-full items-end">
                                <div className="xl:col-span-5 lg:col-span-5 col-span-12">
                                    <h2 className="font-semibold pb-2 text-[20px] text-[#757575] ">
                                        Search by Campaign
                                    </h2>
                                    <input
                                        className="w-full outline-none appearance-none"
                                        // placeholder="asd"
                                        id="default"
                                        type="text"
                                        // name="partnerName"
                                        // value=""
                                        onChange={(e) =>
                                            this.handleChange(
                                                "searchCampaign",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="xl:col-span-5 lg:col-span-5 col-span-12">
                                    <h2 className="font-semibold pb-2 text-[20px] text-[#757575] ">Search by donation purpose</h2>
                                    <input
                                        id="default"
                                        className="w-full outline-none appearance-none"
                                        // placeholder="asd"
                                        type="text"
                                        // name="donationPurposeType"
                                        // value=""
                                        // onChange={(e) => this.donationPurposeName = e.target.value}
                                        onChange={(e) =>
                                            this.handleChange(
                                                "SearchDoantion",
                                                e.target.value
                                            )
                                        }
                                    />

                                </div>
                                <div className="xl:col-span-2 lg:col-span-2 col-span-12">
                                    <div className="flex items-center justify-end">
                                        <CustomButton
                                            text="Search"
                                            width="100px"
                                            // onClick={(e) => this.searchByText(this.partnerName || this.donationPurposeName)}
                                            onClick={() => this.applyFilter()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='partner-tbl'> */}
                    <div className='partner-list-tbl'>

                        <Table
                            columns={[
                                { name: "partnerName", title: "Source" },
                                { name: "donationPurposeName", title: "Donation Purpose" },
                                { name: "url", title: "URL" },
                                { name: "createdDate", title: "Date Created" },
                                { name: "createdBy", title: "Created By" },
                                { name: "donationCount", title: "Donation Count" },
                                { name: "donationAmount", title: "Donation Amount" },
                                { name: "partnerLinkId", title: "Action" },
                                { name: "status", title: "Status" },
                            ]}
                            rows={this.state.allPartnerLinkList}
                            isLoading={this.state.isLoading}
                            sortingColumns={[]}
                            pagination={this.state.pagination}
                            totalResultes={this.state.totalResultes}
                            totalCount={this.state.totalResultes}
                            setPagination={this.setPagination.bind(this)}
                            customScope={[
                                {
                                    column: "partnerLinkId",
                                    renderTableData: this.actions.bind(this),
                                },
                                {
                                    column: "status",
                                    renderTableData: this.status.bind(this),
                                },
                            ]}
                        />
                    </div>

                </div>
                <div id='partnerDiv'>
                    <div className="grid grid-cols-12 gap-6 mb-10 px-10 items-end">
                        <div className="xl:col-span-5 lg:col-span-5 col-span-12">
                            <h2 className="font-semibold pb-2 text-[20px] text-[#757575] ">
                                Source
                            </h2>
                            <input
                                className="w-full outline-none appearance-none"
                                // placeholder="asd"
                                id="default"
                                type="text"
                                name="partnerName"
                                // value=""
                                onChange={(e) =>
                                    this.handleTextChange(
                                        "partnerName",
                                        e.target.value
                                    )
                                }
                                value={this.state.partnerLinkDetails.partnerName}
                            />
                        </div>
                        <div className="2xl:col-span-5 lg:col-span-5 col-span-12 ">
                            <label className="filter-lable block font-medium 2xl:mb-3 lg:[0.25rem] theme-color min-h-30" for="default" >
                                <h2 className="text-xl text-[#757575]">
                                    Donation Purpose
                                </h2>
                            </label>
                            <div className='border-0'>
                                <MultiSelectDropdown
                                    options={this.state.donationNames}
                                    //isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option,
                                    }}
                                    onChange={this.handleChangeInDropdown}
                                    allowSelectAll={false}
                                    value={this.state.donationNames.filter((item) => item.label === this.state.partnerLinkDetails.donationPurposeName)}
                                    drpIdentity="DonationName"
                                />
                                <div>
                                    <ValidationText
                                        error={this.state.validState.error.donationPurposeName}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                            <div className='flex justify-end generate-links'>
                                <CustomButton
                                    text="Generate URL"
                                    width="100px"
                                    onClick={() => this.generateLink()}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-12 gap-6  mb-10 px-10 items-end">
                        <div className="2xl:col-span-10 lg:col-span-10 col-span-12">
                            <label className="filter-lable block font-medium 2xl:mb-3 lg:[0.25rem] theme-color min-h-30" for="default" >
                                <h2 className="text-xl text-[#757575]">
                                    URL Link
                                </h2>
                            </label>
                            <textarea id="url" className="partnerlinks-textarea form-input rounded-none w-full shadow-red  text-lg textarea-color" disabled
                                name="metaDescription"
                                // onChange={(event) => this.handleChange(event)}
                                value={this.state.url}
                                // onBlur={() => this.validateField("metaDescription")}
                                rows="5" cols="50">
                            </textarea>

                        </div>
                        <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                            <div className='flex justify-end '>
                                <CustomButton
                                    text="Save"
                                    width="100px"
                                    onClick={() => this.addEditPartnerLink()}
                                />
                            </div>
                        </div>
                    </div>


                </div>


            </>


        )
    }
}
