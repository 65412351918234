import React, { Component } from "react";
import CustomButton from "../../components/ui/customButton/CustomButton";
import "./Dashboard.css";
import Table from "../../components/table/Table";
import SwalServices from "../../services/swalServices/SwalServices";
import DashboardServices from "../../services/axiosServices/apiServices/DashboardServices";
import moment from "moment";
import { saveAs } from 'file-saver';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.dashboard = new DashboardServices();
    this.swalServices = new SwalServices();
    this.state = {
      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        dateFrom: "",
        dateTo: ""
      },

      allAdminList: [
        {
          adminid: "1",
          donationDate: "1 Mar 2022",
          type: "Upload",
          totalAmount: "$1500",
          donationCampaigns: "General Purpose",
          donationSource: "Give Asia",
          paymentSource: "Give Asia",
        },
        {
          adminid: "1",
          donationDate: "1 Mar 2022",
          type: "Upload",
          totalAmount: "$1500",
          donationCampaigns: "General Purpose",
          donationSource: "Give Asia",
          paymentSource: "Give Asia",
        },
        {
          adminid: "1",
          donationDate: "1 Mar 2022",
          type: "Upload",
          totalAmount: "$1500",
          donationCampaigns: "General Purpose",
          donationSource: "Give Asia",
          paymentSource: "Give Asia",
        },
        {
          adminid: "1",
          donationDate: "1 Mar 2022",
          type: "Upload",
          totalAmount: "$1500",
          donationCampaigns: "General Purpose",
          donationSource: "Give Asia",
          paymentSource: "Give Asia",
        },
      ],
      donationAmount: {
        individualDonation: 0,
        corporateDonation: 0,
        givingSgDonation: 0,
        giveAsiaDonation: 0,
        otherBatches: 0
      },
      // allAdminList: {},
      totalResultes: "",
      dashboardList: []
    };
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    if (this.onPageChange) {
      this.onPageChange();
    }
    this.getDashboardList(newPagination);
  };

  getAllDonationAmount = () => {
    this.dashboard.getAllDonationAmount().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ donationAmount: response.responseItem.responseContent });
      }
      this.setState({ isLoading: false });
    });
  }

  getDashboardList = () => {
    let request = {
      pageNo: this.state.pagination.pageNo,
      pageSize: this.state.pagination.pageSize,
      orderByColumn: this.state.pagination.orderByColumn,
      orderFlag: this.state.pagination.orderFlag,
      searchText: this.state.pagination.searchText,
      dateFrom: this.state.pagination.dateFrom,
      dateTo: this.state.pagination.dateTo ? this.state.pagination.dateTo + " 23:59:59.000" : ''
    }
    this.dashboard.getDashboardList(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let data = response.responseItem.responseContent.itemList;
        data.map((x) => {
          x.donationDate = x.donationDate ? moment(x.donationDate).format("DD MMM YYYY") : '-';
          x.amount = x.amount ? `$${parseFloat(x.amount).toFixed(2)}` : "-";

          x.donationSource = x.donationSource ? x.donationSource : "-";
        });
        this.setState({ dashboardList: data });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount, });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    let fulldate = (detail[name] = value === "" ? null : value);
    let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
    let startLimit = new Date("1/1/1753 12:00:00 AM");
    let endLimit = new Date("12/31/9999 11:59:59 PM");
    detail[name] = value;
    this.setState({ pagination: { ...detail } }, () => {
      if (startLimit < date && date < endLimit) {
        this.getDashboardList();
      } else if (fulldate === null) {
        this.getDashboardList();
      }
    });
  }

  exportData = () => {
    let request = {
      pageNo: this.state.pagination.pageNo,
      pageSize: 100000,
      orderByColumn: this.state.pagination.orderByColumn,
      orderFlag: this.state.pagination.orderFlag,
      searchText: this.state.pagination.searchText,
      dateFrom: this.state.pagination.dateFrom,
      dateTo: this.state.pagination.dateTo ? this.state.pagination.dateTo + " 23:59:59.000" : ''
    }
    this.dashboard.exportData(request).then(
      (response) => {
        if (response != null) {
          var fileName = `ExportDashboardData`;
          var blob = new Blob([response.data])
          saveAs(blob, fileName + ".xlsx");
        }
      }
    )
  }

  purgeData = () => {
    this.dashboard.purgeData().then((response) => {
      this.swalServices.Success("data Purge successfully.");
      this.getDashboardList();
      this.getAllDonationAmount();
    });
  };

  componentDidMount() {
    this.getAllDonationAmount();
    this.getDashboardList();
  }

  render() {
    return (
      <div className="main-body-section">
        <div className="bg-white ">
          <div class="heading-part py-4">
            <div class="flex items-center justify-between">
              <div className="left-tiitle">
                <h3 class="font-bold  text-[#757575] text-4xl">Dashboard</h3>
              </div>
              <div className=" col-span-3 w-full ">
                {/* <div className="flex items-center justify-end">
                  <CustomButton
                    text="Data Purging"
                    width="100px"
                    onClick={() => this.purgeData()}
                  />
                </div> */}
              </div>
            </div>
          </div>


          <div className="grid grid-cols-12 gap-6 py-2 ">
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-12">
              <div className="flex items-center justify-center Individual flex-col h-full">
                <div
                  className="value p-14 pb-5 items-center flex font-bold 2xl:text-[25px] 
                  xl:text-[35px] lg:text-[30px] justify-center "
                >
                  {"$"}{this.state.donationAmount.individualDonation}
                </div>
                <div className="para items-center flex justify-center 2xl:text-[24px] xl:text-[22px] lg:text-[24px] pt-3 pb-16">
                  Individual
                </div>
              </div>
            </div>
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-12">
              <div className="flex items-center justify-center corporate flex-col h-full">
                <div
                  className="value p-14 pb-5 items-center flex font-bold 2xl:text-[25px] 
                  xl:text-[35px] lg:text-[30px] justify-center "
                >
                  {"$"}{this.state.donationAmount.corporateDonation}
                </div>
                <div className="para items-center flex justify-center 2xl:text-[24px] xl:text-[22px] lg:text-[24px] pt-3 pb-16">
                  Corporate
                </div>
              </div>
            </div>
            <div className="2xl:col-span-6 xl:col-span-6 lg:col-span-6 col-span-12 ">
              <div className=" justify-between giving d-flex ">
                <div
                  className="value p-4  items-center flex 2xl:text-[25px] 
                  xl:text-[35px] lg:text-[30px] md:text-[22px]  justify-between "
                >
                  <span className="font-bold">{"$"}{this.state.donationAmount.givingSgDonation}</span>
                  <span className="para items-center flex 2xl:text-[24px] xl:text-[22px] lg:text-[24px]">Giving.SG</span>
                </div>
              </div>
              <div className=" justify-between giving d-flex mt-2">
                <div
                  className="value p-4 items-center flex 2xl:text-[25px] 
                  xl:text-[35px] lg:text-[30px] justify-between "
                >
                  <span className="font-bold">{"$"}{this.state.donationAmount.giveAsiaDonation}</span>
                  <span className="para items-center flex 2xl:text-[24px] xl:text-[22px] lg:text-[24px]">Give Asia</span>
                </div>
              </div>
              <div className=" justify-between giving d-flex mt-2">
                <div
                  className="value p-4 items-center flex 2xl:text-[25px] 
                  xl:text-[35px] lg:text-[30px] justify-between "
                >
                  <span className="font-bold">{"$"}{this.state.donationAmount.otherBatches}</span>
                  <span className="para items-center flex 2xl:text-[24px] xl:text-[22px] lg:text-[24px]">Other Batches</span>
                </div>
              </div>
            </div>

            {/* <div className="2xl:col-span-2 xl:col-span-2 lg:col-span-3 col-span-12">
              <div className="relative justify-center asia">
                <div
                  className="value p-14 pb-5 items-center flex font-bold 2xl:text-[25px] 
                  xl:text-[35px] lg:text-[30px] justify-center "
                >
                  {"$"}{this.state.donationAmount.giveAsiaDonation}
                </div>
                <div className="para items-center flex justify-center 2xl:text-[24px] xl:text-[22px] lg:text-[24px] pt-3 pb-16">
                  Give Asia
                </div>
              </div>
            </div>
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-12">
              <div className="relative justify-center asia">
                <div
                  className="value p-14 pb-5 items-center flex font-bold 2xl:text-[25px] 
                  xl:text-[35px] lg:text-[30px] justify-center "
                >
                  {"$"}{}
                </div>
                <div className="para items-center flex justify-center 2xl:text-[24px] xl:text-[22px] lg:text-[24px] pt-3 pb-16">
                  Other Batches
                </div>
              </div>
            </div> */}
          </div>
          <div className="grid grid-cols-12 gap-9 pt-8 mb-6">
            <div className=" col-span-9 ">
              <div className="flex items-center  ">
                <h3 class="font-bold  pr-7 text-[#757575] xl:text-[25px] lg:text-[20px] md:text-[18px]">
                  Search by Date Range
                </h3>
                <div className="xl:col-span-6 lg:col-span-6 pl-7 pt-4 col-span-12">
                  <div className="grid grid-cols-12 gap-9 items-center">
                    <div className=" xl:col-span-5  lg:col-span-5 col-span-12">
                      <input
                        id="default"
                        name="dateFrom"
                        placeholder="Select"
                        className="date-pickers form-input rounded-nonepy-3 text-lg"
                        type="date"
                        value={this.state.pagination.dateFrom}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div className=" xl:col-span-2 lg:col-span-2 col-span-12 text-center">
                      <h2 className="text-xl theme-color">To</h2>
                    </div>
                    <div className=" xl:col-span-5 lg:col-span-5 col-span-12">
                      <input
                        id="default"
                        name="dateTo"
                        className="date-pickers form-input rounded-none  shadow-red py-3 text-lg"
                        type="date"
                        value={this.state.pagination.dateTo}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-span-3 w-full ">
              <div className="flex items-center justify-end">
                <CustomButton
                  text="Export"
                  width="100px"
                  onClick={() => this.exportData()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="account-list-tble pt-7 ">
          <Table
            columns={[
              { name: "donationDate", title: "Donation Date" },
              { name: "type", title: "Type" },
              { name: "amount", title: "Total Amount" },
              { name: "donationCampaign", title: "Donation Campaigns" },
              { name: "donationSource", title: "Donation Source" },
              { name: "PaymentSource", title: "Payment Source" },
              { name: "ReferenceNo", title: "Ref No." }
            ]}
            rows={this.state.dashboardList}
            isLoading={this.state.isLoading}
            sortingColumns={["userName", "email"]}
            pagination={this.state.pagination}
            totalResultes={this.state.totalResultes}
            totalCount={this.state.totalResultes}
            setPagination={this.setPagination.bind(this)}
            customScope={
              [
                // {
                //   column: "adminId",
                //   renderTableData: this.actions.bind(this),
                // },
              ]
            }
          />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {};

export default Dashboard;
