import React, { Component } from "react";
// import LogoImage from '../../images/logo.png';
import { ErrorMessage } from "../../utils/Messages";
import SwalServices from "../../services/swalServices/SwalServices";
import CustomTextbox from "../../components/ui/customTextbox/CustomTextbox";
import AuthenticationServices from "../../services/axiosServices/apiServices/AuthenticationServices";
import { Navigate } from "react-router-dom";
import { setAuthProps } from "../../utils/AuthenticationLibrary";
import ValidationText from "../../utils/validation/ValidationText";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.authenticationServices = new AuthenticationServices();
    this.swalServices = new SwalServices();
    this.state = {
      isLoading: false,
      redirect: "",
      userDetails: {
        userName: "",
        password: "",
      },
      validationRules: {
        userName: [
          {
            type: "require",
            message: ErrorMessage.FieldRequired.replace("{0}", "Email address"),
          },
          {
            type: "email",
            message: ErrorMessage.NOTVALIDPATTERN.replace(
              "{0}",
              "Email address"
            ),
          },
        ],
        password: [
          {
            type: "require",
            message: ErrorMessage.FieldRequired.replace("{0}", "Password"),
          },
          //   { type: 'password', message: ErrorMessage.PasswordNotValid }
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }
  redirectToDashboard = () => {
    this.setState({ route: "/Dashboard" });
  };

  login = () => {
    if (this.isValidateAllFields()) {
      this.setState({ isLoading: true });
      this.authenticationServices.adminLogin(this.state.userDetails).then((response) => {
        console.log("login", response)
        if (response.statusCode === 200 && response.responseItem) {
          let authDetails = response.responseItem.responseContent;
          setAuthProps(authDetails);
          this.setState({ route: '/Dashboard' });
        }
        else {
          this.swalServices.Error(ErrorMessage.InvalidAuthorization);
        }
        this.setState({ isLoading: false });
      });
    }
  }

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.userDetails,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.userDetails,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  // login = () => {
  //   if (this.isValidateAllFields()) {
  //     let authDetails = {
  //       adminUserId: 1,
  //       isAuthenticated: true,
  //       isSuperAdmin: null,
  //       message: null,
  //       sessionTimeout: 60,
  //       token: {
  //         token:
  //           "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1l…0.SQU23jwLWfjpY3yXz7chpHQoQQW8fHybJgrfRRIToTg",
  //         expires: "2024-06-23T11:41:41.8305761Z",
  //       },
  //       userDisplayName: null,
  //       userName: "Admin@gmail.com",
  //     };
  //     setAuthProps(authDetails);
  //     this.setState({ route: "/Dashboard" });
  //   }
  // };

  handleChange = (key, value) => {
    let newUserDetails = { ...this.state.userDetails };
    newUserDetails[key] = value;
    this.setState({ userDetails: newUserDetails });
  };
  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="login-page-sec form-box">
        <div className="logo bg-[#0e61b4] p-4">
          <img className="max-w-[10%]" src="/logo.png" />
        </div>

        <div className="flex items-center justify-center login-sec-main-view">
          <div className="login-main-bg bg-[#ffffffb0] w-[40%]">
            <div className="text-[#6e6e6e] text-center text-[40px] font-bold pb-14">
              <h2>Admin Login</h2>
            </div>
            <div className="admin-input-part">
              <div className="input-title flex items-center mb-3">
                <label
                  for="email-address"
                  className="text-[#6e6e6e] font-bold text-lg"
                >
                  Email address
                </label>
                <span className="text-[#c00000] pl-1"> * </span>
              </div>
              <div className="relative">
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="w-full px-3 py-2 border focus:outline-none focus:border-[#0e61b4] focus:ring-1 focus:[#0e61b4]
               focus:invalid:border-[#0e61b4] focus:invalid:ring-[#0e61b4]"
                  value={this.state.userDetails.userName}
                  onChange={(event) =>
                    this.handleChange("userName", event.target.value)
                  }
                  onBlur={() => this.validateField("userName")}
                />
                <ValidationText
                  className="absolute left-0 bottom-0"
                  error={this.state.validState.error.userName}
                />
              </div>
            </div>

            <div className="admin-input-part mt-7">
              <div className="input-title flex items-center mb-3">
                <label
                  for="password"
                  className="text-[#6e6e6e] font-bold text-lg"
                >
                  Password
                </label>
                <span className="text-[#c00000] pl-1"> * </span>
              </div>
              <div className="relative mb-5">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="w-full px-3 py-2 border focus:outline-none focus:border-[#0e61b4] focus:ring-1 focus:[#0e61b4]
               focus:invalid:border-[#0e61b4] focus:invalid:ring-[#0e61b4]"
                  value={this.state.userDetails.password}
                  onChange={(event) =>
                    this.handleChange("password", event.target.value)
                  }
                  onBlur={() => this.validateField("password")}
                />
                <ValidationText
                  className="absolute left-0 bottom-0"
                  error={this.state.validState.error.password}
                />
              </div>
             
            </div>
            {this.state.isLoading ?
              <div className="flex justify-center">
               
                <button
                  type="submit"
                  className="bg-[#0e61b4] group w-full flex justify-center py-3 px-4 mt-5 h-auto  text-sm font-medium text-white"
                >
                  <i className="fa fa-spinner fa-spin fa-1x fa-fw mr-2 mt-1"></i>
                  Loading                 
                </button>
              </div>
              :
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="bg-[#0e61b4] group w-full flex justify-center py-3 px-4 mt-5 h-auto  text-sm font-medium text-white"
                  onClick={() => this.login()}
                >
                  Login
                </button>
              </div>}

          </div>
        </div>

        <div className="bg-[#e5e5e5] text-center py-5 fixed bottom-0 w-full">
          <p>&copy; BOYS’ TOWN ALL RIGHTS RESERVED | POWERED BY FLYING CAPE</p>
        </div>
      </div>
    );
  }
}
