import React, { Component } from "react";
import MultiSelectDropdown from "../../components/dropdown/MultiSelectDropdown";
import CustomButton from "../../components/ui/customButton/CustomButton";
import NotificationServices from "../../services/axiosServices/apiServices/NotificationServices";
import moment from "moment";
import SwalServices from "../../services/swalServices/SwalServices";

export default class Notification extends Component {
  constructor(props) {
    super(props);
    this.notificationServices = new NotificationServices();
    this.swalServices = new SwalServices();

    this.state = {

      referenceNo: "",
      fontStylearr: [
        {
          value: 1,
          label: "Normal",
        },
        {
          value: 2,
          label: "Bold",
        },
        {
          value: 3,
          label: "Italic",
        },
        {
          value: 4,
          label: "Bold-Italic",
        },

      ],
      donationAmount: [],
      isDisabledNotificationBar: false,
      notificationDetails: {
        notificationId: 0,
        bgColorCode: '',
        textColorCode: '',
        notificationText: '',
        urlLink: '',
        isDisabled: false,
        txtStyle: '',
        startDate: '',
        endDate: '',
        donationAmount: null,
        isAdmin: true,
      },
      charLimit: 250,
    };
  }


  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.notificationDetails;
    detail[name] = value;
    this.setState({ notificationDetails: { ...detail } });
    //console.log(this.state.notificationDetails);
  }
  handleChangeInDropdown = (selected, drpIdentity) => {
    var details = this.state.notificationDetails;
    details.txtStyle = selected.label;

    this.setState({ notificationDetails: details });
    console.log(this.state.notificationDetails);
  };

  componentDidMount() {
    this.getNotificationDetails();
  }
  addEditNotificationDetails = () => {
    let request = this.state.notificationDetails;
    request.isDisabled = this.state.isDisabledNotificationBar;

    this.notificationServices
      .addEditNotificationDetails(request)
      .then((response) => {

        if (
          response.statusCode === 200 &&
          response.responseItem != null &&
          response.responseItem.responseContent.hasError !== true
        ) {
          // this.resetFields();
          this.swalServices.Success("Notification details inserted successfully.");

        } else {
          this.swalServices.Error(" Somthing went wrong.");
        }
      });
  };
  getNotificationDetails = () => {
    let id = 1;
    this.notificationServices
      .getNotificationDetails(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let data = response.responseItem.responseContent;
          if (data) {
            data.startDate = data.startDate != null ? moment(data.startDate).format("yyyy-MM-DD") : data.startDate;
            data.endDate = data.endDate != null ? moment(data.endDate).format("yyyy-MM-DD") : data.endDate;

            this.setState({
              notificationDetails: data,
              isDisabledNotificationBar: response.responseItem.responseContent.isDisabled,
            });
          }

        } else {
          this.swalServices.Error(response.message);
        }
      });
  };
  render() {
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white ">
            <div class="heading-part py-4">
              <div class="flex items-center justify-between">
                <div className="left-tiitle ">
                  <h3 class="font-bold  text-[#757575] text-3xl">
                    Notification Bar
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="middle-section">
            <div className="form-start bg-white border border-[#c9c9c9] pb-9 px-7">
              <div className="flex items-center mt-7">
                <div className="mr-3">
                  <h2 class="font-semibold pb-2 text-[20px] text-[#757575] ">
                    Disable Notification Bar
                  </h2>
                </div>
                <div className="form-switch mr-4">
                  <input
                    type="checkbox"
                    id="Notification"
                    className="sr-only"
                    onChange={() =>
                      this.setState({
                        isDisabledNotificationBar:
                          !this.state.isDisabledNotificationBar,
                      })
                    }
                    value={this.state.isDisabledNotificationBar}
                    checked={this.state.isDisabledNotificationBar}
                  />
                  <label className="bg-[#757575]" htmlFor="Notification">
                    <span
                      className="bg-white shadow-sm"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-6 mt-5">
                <div className=" xl:col-span-5 lg:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className=" xl:col-span-6 lg:col-span-6 col-span-12">
                          <h2 class="font-semibold pb-2 text-[20px] text-[#757575] ">
                            Start Date<span class="text-[#C00000]">*</span>
                          </h2>
                          <input
                            id="default"
                            name="startDate"
                            placeholder="Select"
                            className="w-full date-pickers form-input rounded-nonepy-3 text-lg"
                            type="date"
                            value={this.state.notificationDetails.startDate}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                        <div className=" xl:col-span-6 lg:col-span-6 col-span-12">
                          <h2 class="font-semibold pb-2 text-[20px] text-[#757575] ">
                            End Date<span class="text-[#C00000]">*</span>
                          </h2>
                          <input
                            id="default"
                            name="endDate"
                            placeholder="Select"
                            className="w-full date-pickers form-input rounded-nonepy-3 text-lg"
                            type="date"
                            value={this.state.notificationDetails.endDate}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                          <h2 class="font-semibold pb-2 text-[20px] text-[#757575] ">
                            Background Color Code:
                            <span class="text-[#C00000]">*</span>
                          </h2>
                          <input
                            id="default"
                            name="bgColorCode"
                            placeholder="Select"
                            className="cursor-pointer border border-[#6b7280] w-full form-input rounded-nonepy-3 text-lg"
                            type="color"
                            value={this.state.notificationDetails.bgColorCode}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                          <h2 class="font-semibold pb-2 text-[20px] text-[#757575] ">
                            Text color code:
                            <span class="text-[#C00000]">*</span>
                          </h2>
                          <input
                            id="default"
                            name="textColorCode"
                            placeholder="Select"
                            className="cursor-pointer border border-[#6b7280] w-full form-input rounded-nonepy-3 text-lg"
                            type="color"
                            value={this.state.notificationDetails.textColorCode}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                          <h2 class="font-semibold pb-2 text-[20px] text-[#757575] ">
                            Notification Text:
                            <span class="text-[#C00000]">*</span>
                          </h2>
                          <input
                            id="default"
                            name="notificationText"
                            placeholder=""
                            className="border border-[#6b7280] w-full form-input rounded-nonepy-3 text-lg"
                            type="text"
                            maxLength={250}
                            value={this.state.notificationDetails.notificationText}
                            onChange={(e) => this.handleChange(e)}
                          />
                          {
                            (this.state.charLimit - this.state.notificationDetails.notificationText.length) <= 0 ?
                              (
                                <div className="text-[#C00000] w-[300px] rounded mt-2 mb-1">You reach the character enter limit</div>
                              )
                              :
                              this.state.charLimit - this.state.notificationDetails.notificationText.length <= 50 ?
                                (
                                  <div className={`mb-1 rounded text-[#C00000] w-full mt-2`}>
                                   {` ${this.state.charLimit - this.state.notificationDetails.notificationText.length}/${this.state.charLimit} characters remaining`}
                                   
                                  </div>
                                )
                                :
                                null
                          }

                          {/* <div className="text-white bg-[#717171] w-[300px] rounded text-center mt-2 mb-1">You reach the character enter limit</div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                          <div className="xl:col-span-4 lg:col-span-4 col-span-12">
                            <h2 className="font-semibold pb-2 text-[20px] text-[#757575] ">
                              Text Style
                              <span className="text-[#C00000]">*</span>
                            </h2>
                            <MultiSelectDropdown
                              options={this.state.fontStylearr}
                              // isMulti
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              // components={{
                              //  Option,
                              //}}
                              onChange={this.handleChangeInDropdown.bind(
                                this
                              )}
                              allowSelectAll={false}
                              value={this.state.fontStylearr.filter(
                                (item) =>
                                  item.label === this.state.notificationDetails.txtStyle
                              )}
                              drpIdentity="DonationAmount"
                            />

                          </div>
                        </div>
                      </div>
                    </div>


                    {/* <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                          <h2 class="font-semibold pb-2 text-[20px] text-[#757575] ">
                          Text Style:
                            <span class="text-[#C00000]">*</span>
                          </h2>
                          <input
                            id="default"
                            name="txtStyle"
                            placeholder=""
                            className="border border-[#6b7280] w-full form-input rounded-nonepy-3 text-lg"
                            type="text"
                            value={this.state.notificationDetails.txtStyle}
                              onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                          <h2 class="font-semibold pb-2 text-[20px] text-[#757575] ">
                            URL Link:
                            <span class="text-[#C00000]">*</span>
                          </h2>
                          <input
                            id="default"
                            name="urlLink"
                            placeholder=""
                            className="border border-[#6b7280] w-full form-input rounded-nonepy-3 text-lg"
                            type="text"
                            value={this.state.notificationDetails.urlLink}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 flex justify-end">
                      <div class="btn">
                        <CustomButton text="Save" width="100px" onClick={() => this.addEditNotificationDetails()} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
