import React from "react";

import validator from 'validator';

export const required = (value) => {
  if (value === undefined || value === null) {
    return false;
  }
  if (!value.toString().trim().length) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return false;
  }
  if (typeof (value) !== 'string') {
    if (value === 0) {
      return false;
    }
  }
  return true;
};

export const email = (value) => {
  if (value === undefined || value === null || value.toString() === "") {
    return true;
  }
  if (!validator.isEmail(value)) {
    return false;
  }
  return true;
};

export const number = (value) => {
  if (value === 0) {
    return false;
  }
  return true;
};

export const maxLength = (value, maxLen) => {
  // get the maxLength from component's props
  if ((value.toString().trim().length <= maxLen)) {
    // Return jsx
    return true;
  }
  return false;
};

export const minLength = (value, minLen) => {
  // get the maxLength from component's props
  if ((value.toString().trim().length >= minLen)) {
    // Return jsx
    return true;
  }
  return false;
};

export const compare = (value, compareValue) => {  
  // get the maxLength from component's props
  if (!(value === compareValue)) {
    // Return jsx
    return false;
  }
  return true;
};

export const distinct = (value, compareValue) => {    
  // get the maxLength from component's props
  if(value !== ""){
    if (value === compareValue) {
      // Return jsx
      return false;
    }
  }  
  return true;
};

export const maxProspects = (value, maxProspectsValue) => {
  // get the maxLength from component's props
  if ((value <= maxProspectsValue)) {
    // Return jsx
    return true;
  }
  return false;
};

export const minEndDate = (date, minEndDateValue) => {
  // get the maxLength from component's props
  if ((date >= minEndDateValue)) {
    // Return jsx
    return true;
  }
  return false;
};
export const maxSum = (sum, maxSumValue) => {
  // get the maxLength from component's props
  if ((sum <= maxSumValue)) {
    // Return jsx
    return true;
  }
  return false;
};

export const password = (value, props, components) => {
  // NOTE: Tricky place. The 'value' argument is always current component's value.
  // So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
  // But if we're changing 'confirm' component - the condition will always be true
  // If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.
  if (value !== components['confirm'][0].value) { // components['password'][0].value !== components['confirm'][0].value
    // 'confirm' - name of input
    // components['confirm'] - array of same-name components because of checkboxes and radios
    return <span className="error">Passwords are not equal.</span>
  }
};

export const uniqueIdentifier = (value) => {
  if (value !== undefined && value !== null && value.toString().trim().length) {
    const pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
};

export const isvalidPassword = (value) => {
  if (value !== undefined && value !== null && value.toString().trim().length) {
    const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}