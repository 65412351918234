import React from "react";

export default function ActionEditButton(props) {
    return (
        <button className='action-btn mr-4 flex items-center' aria-controls="add-edit-modal"  id={props.index}
            onClick={(event) => props.onActionClick(props.value, props.index,event)}
        >
          
         <i class="fa fa-pencil fa-2x" aria-hidden="true"></i>
               
           
          
            
        </button>
    )
}
